const SET_ACTIVE_ORGANIZATION = (state, payload) => {
  state.activeOrganization = payload
}
const SET_ORGANIZATIONS = (state, payload) => {
  state.organizations = payload
}

export default {
  SET_ORGANIZATIONS,
  SET_ACTIVE_ORGANIZATION
}
