<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="items"
    :sort-by="sortBy"
    class="contact-listing-app"
    :search="search"
    :show-select="showSelect"
    @click:row="handleClick"
    :class="[tableUser ? 'table-user' : 'table-data']"
    style="cursor: pointer"
    @toggle-select-all="selectAllToggle"
    hide-default-footer
  >
    <!-- eslint-disable-next-line -->
      <template v-slot:item.data="{ item }">
      <div
        class="d-flex align-center flex-column flex-sm-row tw-w-full"
        :class="isSelected ? 'selected' : ''"
      >
        <div class="mr-4">
          <v-img
            v-if="item && item.data.company.logo"
            :src="item.data.company.logo"
            :width="$vuetify.breakpoint.xs ? '80px' : '45px'"
            class="rounded-circle img-fluid"
          ></v-img>
        </div>
        <div class="tw-text-xl sm:tw-text-lg tw-mb-3 sm:mb-0">
          {{ item.data.company.name.legal }}
          <span
            v-if="item.data.company.name.trade"
            class="subtitle-2 font-weight-regular d-block"
            :class="{'text-no-wrap': !$vuetify.breakpoint.xs}"
          >{{ item.data.company.name.trade }}</span>
        </div>

      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.data-table-select="{ item, isSelected, select }">
      <template v-if="item.selectDisabled">
        <v-tooltip bottom>
          <!-- eslint-disable-next-line -->
          <template v-slot:activator="{ on, attrs }">
            <v-simple-checkbox
              :value="isSelected"
              :readonly="item.selectDisabled"
              :disabled="item.selectDisabled"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>
          <span>{{ item.disabledDescription || '' }}</span>
        </v-tooltip>
      </template>
      <template v-else>
        <v-simple-checkbox :value="isSelected" @input="select($event)"></v-simple-checkbox>
      </template>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.title="{ item }">

        <div :class="$vuetify.breakpoint.xs ? 'tw-w-full tw-text-2xl tw-py-2 tw-text-center': ''">
          {{ item.title }}
          <span
            v-if="item.subtitle"
            class="subtitle-2 font-weight-regular d-block"
            :class="{'text-no-wrap': !$vuetify.breakpoint.xs}"
          >
            {{ item.subtitle }}
          </span>
        </div>

    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:no-data>{{ text.emptyLabel }}</template>
  </v-data-table>
</template>
<script>
export default {
  props: {
    tableUser: Boolean,
    text: {},
    headers: {},
    sortBy: {},
    items: {},
    showSelected: {},
    showSelect: {},
    disabledDescription: {},
    displayActionItems: {
      type: Boolean,
      default: true
    },
    emit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isSelected: null,
    disabledCount: 0,
    selected: [],
    actionItems: [
      {
        title: 'Cancelar Seleção',
        icon: 'mdi-delete-outline',
        needSelect: true
      },
      {
        title: 'Deletar Selecionados',
        icon: 'mdi-delete-outline',
        needSelect: true
      }
    ],
    dialog: false,
    singleSelect: false,
    search: ''
  }),
  methods: {
    selectAllToggle (props) {
      if (this.selected.length !== this.items.length - this.disabledCount) {
        this.selected = []
        const self = this
        props.items.forEach((item) => {
          if (!item.selectDisabled) {
            self.selected.push(item)
          }
        })
      } else this.selected = []
    },
    removeSelection () {
      this.selected = []
    },
    deletePeople () { this.$emit('delete', this.selected) },
    handleClick (item) {
      if (this.emit) {
        this.$emit('select', item)
      } else if (item.url) {
        this.$router.push({ path: item.url })
      }
    },
    setFavorite (item) {
      const index = this.items.indexOf(item)
      this.items[index].favorite = !this.items[index].favorite
    }
  },
  created () {
    const self = this
    this.items.map((item) => {
      if (item.selectDisabled) self.disabledCount += 1
    })
  },
  mounted () {
    this.actionItems = [
      {
        title: 'Cancelar Seleção',
        icon: 'mdi-delete-outline',
        action: () => { this.removeSelection() },
        needSelect: true
      },
      {
        title: 'Deletar Selecionados',
        icon: 'mdi-delete-outline',
        action: () => { this.deletePeople() },
        needSelect: true
      }
    ]
  }
}
</script>
<style>
.v-data-table.table-data > .v-data-table__wrapper .v-data-table__mobile-table-row .v-data-table__mobile-row .v-data-table__mobile-row__cell {
  width: 100% !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row:last-child {
  padding-bottom: 40px !important;
}
.v-data-table.table-user
  >.v-data-table__wrapper >
  .v-data-table__mobile-table-row >
  .v-data-table__mobile-row >
  .v-data-table__mobile-row__header {
  display: none !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row {
  padding: 2px 0 !important;
  min-height: auto !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row__cell {
  width: 100% !important;
  text-align: center !important;
}
.v-data-table.table-user
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row__cell
  .v-simple-checkbox {
  width: 100% !important;
  text-align: left !important;
  padding: 0.5rem 1rem !important;
}

.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row {
  flex-direction: column !important;
  align-items: flex-start;
}
.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row
  .v-data-table__mobile-row__cell {
  text-align: left;
}
.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row
  .v-data-table__mobile-row__cell
  .font-datatable-item {
  min-width: 235px;
  width: 100%;
  word-wrap: normal;
}
.v-data-table.table-data
  > .v-data-table__wrapper
  .v-data-table__mobile-table-row
  .v-data-table__mobile-row {
  padding: 6px 18px !important;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  background: #efefef;
  border-radius: 10px;
  margin: 6px 18px;
}
.v-data-table__wrapper .v-data-table__mobile-table-row.v-data-table__selected {
  background: #ceebff !important;
}
</style>
