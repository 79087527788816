<template>
  <v-card-actions>
    <v-spacer />
    <v-btn large @click="$emit('save')" color="primary">Salvar</v-btn>
  </v-card-actions>
</template>
<script>
export default {
  data: () => ({
  })
}
</script>
