<template>
  <v-card-actions>
    <v-btn large @click="$emit('clear')" text color="primary">Limpar</v-btn>
    <v-spacer />
    <v-btn large @click="$emit('submit')" color="primary">Enviar</v-btn>
  </v-card-actions>
</template>
<script>
export default {
  data: () => ({
  })
}
</script>
