
<template>
   <svg width="24" height="24" viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_719_213)">
        <path d="M20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C12.76 4 13.5 4.11 14.2 4.31L15.77 2.74C14.61 2.26 13.34 2 12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12H20Z" :fill="color"/>
        <path d="M8.41 7.5L7 8.92L11.5 13.42L21.5 3.42L20.09 2L11.5 10.59L8.41 7.5Z" :fill="color"/>
        <path d="M8.41 12.5L7 13.92L11.5 18.42L21.5 8.42L20.09 7L11.5 15.59L8.41 12.5Z" :fill="color"/>
        </g>
        <defs>
        <clipPath id="clip0_719_213">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black'
    }
  }
}
</script>

<style scoped>

</style>
