<template>
    <v-rating
        :value="value"
        empty-icon="mdi-star-outline"
        full-icon="mdi-star"
        hover
        :length="length"
        color="yellow darken-3"
        background-color="grey darken-1"
        readonly
    ></v-rating>
</template>

<script>
export default {
  props: ['question'],
  computed: {
    value () {
      return this.question.studentAnswer.answers[0]
    },
    length () {
      return this.question.wordings.data.params.length || 5
    }
  }
}
</script>
