var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 px-5",attrs:{"headers":_vm.headers,"items":_vm.itemsComputed,"items-per-page":10},on:{"click:row":function($event){return _vm.openQuestion($event)}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.title.length > 50 ? ((item.title.substring(0, 50)) + "...") : item.title)+" ")])]}}],null,true)},[_c('span',[_vm._v("Clique para visualizar a questão")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(parseInt(item.studentAnswer.final_grade) === parseInt(item.wordings.data.grade) )?_c('TooltipStatus',{attrs:{"icon":"mdi-comment-check-outline","color":"green","title":"Questão correta"}}):(item.studentAnswer.final_grade > 0)?_c('TooltipStatus',{attrs:{"icon":"mdi-comment-bookmark-outline","color":"blue","title":"Questão pontuada"}}):(item.studentAnswer.status === 'NEED_BE_CORRECTED')?_c('TooltipStatus',{attrs:{"icon":"mdi-comment-edit-outline","color":"orange","title":"Questão precisa de correção"}}):(item.studentAnswer.status === 'CANCELED')?_c('TooltipStatus',{attrs:{"icon":"mdi-close","color":"red","title":"Questão cancelada"}}):(item.studentAnswer.answers.length)?_c('TooltipStatus',{attrs:{"icon":"mdi-comment-remove-outline","color":"red","title":"Questão incorreta"}}):(item.studentAnswer.status === 'NOT_ANSWERED')?_c('TooltipStatus',{attrs:{"icon":"mdi-comment-off-outline","title":"Questão não respondida"}}):_vm._e()]}}])}),_c('ResultDialogQuestion',{attrs:{"question":_vm.actualQuestion},model:{value:(_vm.questionDialog),callback:function ($$v) {_vm.questionDialog=$$v},expression:"questionDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }