<template>
  <div v-if="duration > 0">
    <span
      class="body-2 mr-2"
      :class="navbar ? 'tw-text-gray-600' : 'tw-text-gray-300' "
    >{{ timer.display }}</span>
    <v-progress-circular rotate="270" :value="timer.progress" :color="navbar ? 'blue' : 'white'"></v-progress-circular>
  </div>
</template>
<script>
import { parseISO, differenceInSeconds } from 'date-fns'

export default {
  props: {
    startDate: {
      type: String,
      default: null
    },
    end: {
      type: Number,
      default: null
    },
    duration: {
      type: Number,
      default: 0
    },
    status: {
      type: String,
      default: null
    },
    navbar: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    timer: {
      countdown: null,
      display: null,
      progress: null,
      total: null
    }
  }),
  methods: {
    setTimer () {
      this.timer.total = this.duration * 60
      if (this.status === 'NOT_STARTED' && this.startDate === null) {
        this.timer.countdown = this.timer.total
      } else {
        const timeDiff = differenceInSeconds(parseISO(this.startDate), new Date(Date.now()))
        this.timer.countdown = this.timer.total + timeDiff // timeDiff is negative
      }
    },
    timerCountdown () {
      if (this.timer.countdown > 0) {
        setTimeout(() => {
          this.timer.countdown -= 1
          this.timer.display = new Date(this.timer.countdown * 1000)
            .toISOString()
            .substr(11, 8)
          this.timer.progress = (this.timer.countdown / this.timer.total) * 100
          this.timerCountdown()
        }, 1000)
      } else {
        this.$emit('finishTimer')
        this.timer.display = 'Tempo encerrado!'
      }
    }
  },
  mounted () {
    if (this.duration > 0) {
      this.setTimer()
      setTimeout(this.timerCountdown(), 1000)
    }
  }
}
</script>
