<template>
    <v-dialog v-model="value" max-width="800" persistent>
        <template #default>
            <v-card class="px-5">
                <v-row>
                    <v-col cols="12" md="12">
                        <ResultAdditionalInformation
                            :question="question"
                        />
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                        <v-card class="text-center" outlined elevation="0">
                            <v-card-title class="justify-center blue darken-1 white--text py-2 mb-4">Enunciado</v-card-title>
                            <v-card-text style="font-size: 15px">
                                <p class="text-h5" v-html="question.main_text"></p>
                            </v-card-text>
                          </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12">
                        <v-card class="text-center" outlined elevation="0">
                            <v-card-title class="justify-center blue darken-1 white--text py-2 mb-4">Resposta</v-card-title>
                            <v-card-text style="font-size: 15px">
                                <ResultQuestionPreview
                                    :question="question"/>
                            </v-card-text>
                          </v-card>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-row>
                        <v-col cols="12" md="12" class="text-end my-3">
                            <v-btn @click="$emit('input', false)" color="primary" outlined>Fechar</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>

import { getNameStatus } from '../../utils/helpers'
import ResultAdditionalInformation from './ResultAdditionalInformation.vue'
import ResultQuestionPreview from './ResultQuestionPreview.vue'

export default {
  props: ['value', 'question'],
  components: { ResultAdditionalInformation, ResultQuestionPreview },
  methods: {
    getNameStatus
  }
}
</script>
