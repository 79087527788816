<template>

    <v-menu
      :key="text"
      rounded
      offset-y
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          :color="color"
          class="white--text mr-2"
          v-bind="attrs"
          v-on="on"
          :outlined="outlined"
        >
          <v-icon left v-if="icon">
            {{ icon }}
          </v-icon>
          {{ text }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item"
          link
          @click="action(item)"
        >
         <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="item.text"></v-list-item-title>
        </v-list-item>
          <slot></slot>
      </v-list>
    </v-menu>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    },
    color: {
      type: String,
      default: 'primary'
    },
    text: {
      type: String
    },
    icon: {
      type: String
    },
    outlined: {
      type: Boolean
    }
  },
  data: () => ({
  }),
  methods: {
    action (item) {
      if (item.action) {
        this.$emit(item.action)
      } else if (item.url) {
        this.$router.push({ path: item.url })
      }
    }
  }
}
</script>
