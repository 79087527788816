<template>
  <v-overlay :value="download">
    <div class="text-center">
      <v-row
          class="fill-height"
          align-content="center"
          justify="center"
        >
          <v-col
            class="text-subtitle-1 text-center"
            cols="12"
          >
            Baixando prova
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="white accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
    </div>
  </v-overlay>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  data () {
    return {
      showBack: false,
      interval: null
    }
  },
  computed: {
    ...mapState(['download'])
  },
  methods: {
    ...mapMutations(['setDownload']),
    back () {
      this.showBack = false
      this.setDownload(false)
      this.$router.go(-1)
    }
  },
  watch: {
  }
}
</script>
