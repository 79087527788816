import api from '@/libs/axios'

const fetchOrganizations = async ({ commit }) => {
  const url = '/organizations?page=1&per_page=25'
  try {
    const response = await api.get(url)
    commit('SET_ORGANIZATIONS', response.data.items)
  } catch (error) {
    console.error(error)
  }
}

const clearOrganization = ({ commit }) => {
  localStorage.removeItem('ACTIVE_ORGANIZATION')
  commit('SET_ORGANIZATIONS', {})
  commit('SET_ACTIVE_ORGANIZATION', {})
}

export default {
  fetchOrganizations,
  clearOrganization
}
