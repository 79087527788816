<template>
    <div>
        <div v-for="option in optionsFiltered" :key="option.id">
            <v-card
              elevation="2"
              class="d-flex pa-4 mb-3"
              :class="{
                  'correct': option.studentCheck && option.is_correct,
                  'wrong': option.studentCheck && !option.is_correct,
                  'correct_answer': option.is_correct && !option.studentCheck
                }"
              >
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                    v-model="option.studentCheck"
                    hide-details
                    readonly
                    class="pe-2 flex align-center"
                    />
                </v-col>
                <v-col cols=12>
                  <span v-html=option.answer></span>
                </v-col>
              </v-row>
            </v-card>
        </div>
    </div>
</template>

<script>
import collect from 'collect.js'

export default {
  name: 'MutipleChoiceMutipleAnswerPreview',
  props: ['options', 'question'],
  data: () => ({
    iFrameRegex: /<iframe[^>]*src=["|']([^'"]+)["|'][^>]*>/g
  }),
  computed: {
    optionsFiltered () {
      const answer = this.question.studentAnswer.answers[0]
      const merged = collect(this.options).keys().map(key => {
        return collect(this.options[key]).merge({
          id: key,
          studentCheck: answer === key
        }).all()
      })
      return collect(merged)
        .map(option => {
          const answerFormated = this.formatAnswer(option.answer)
          return {
            ...option,
            answer: option.answer.replace(this.iFrameRegex, answerFormated)
          }
        })
        .all()
    }
  },
  methods: {
    formatAnswer (answer) {
      const match = answer.match(this.iFrameRegex)
      if (!match) {
        return
      }
      return match.toString()
        .replace(/width=\S+/g, 'width="500"')
        .replace(/height=\S+/g, 'height="350"')
        .replace('allowfullscreen="allowfullscreen"', '')
    }
  }
}
</script>

<style scoped>
.wrong {
  background-color: #ef9a9a;
}
.correct {
  background-color: #81c784;
}
.correct_answer {
  background-color: #7dbce6;
}

.text {
  font-weight: 900;
}
</style>
