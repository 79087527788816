<template>
<v-dialog
  transition="dialog-bottom-transition"
  max-width="800"
  hide-overlay
  v-model="display"
>
  <template>
    <v-card>
      <v-toolbar
        color="primary"
        dark
        class="mb-3"
      >Detalhes sobre "{{ item && item.title }}"</v-toolbar>
      <v-card-text class="text">
        <p class="body-2 font-weight-medium my-0">Descrição</p>
        <p class="body-2 my-0" v-html="item && item.description && item.description"></p>
        <p class="body-2 font-weight-medium my-0 mt-2">Status</p>
        <p class="my-0 text-uppercase bullet-info"
          :class="item && item.student_exam.status_color"
        >
          {{ item && item.student_exam.status_formated }}
        </p>
        <p class="body-2 font-weight-medium my-0 mt-2">Professor responsável</p>
        <p class="body-2 my-0 mb-2">
          <v-avatar color="primary" size="30" class="mr-1">
            <img :src="item && item.author.avatar" />
          </v-avatar>
          {{ item && item.author.name }}
        </p>
        <p class="body-2 font-weight-medium my-0 mt-2">Turma</p>
        <p class="body-2 my-0 mb-2">
          <v-avatar color="primary" size="30" class="mr-1">
            <img :src="item && item.course.avatar" />
          </v-avatar>
          {{ item && item.course.name }}
        </p>
        <v-divider />
        <v-row>
          <v-col cols="12" md="6">
            <p class="body-2 font-weight-bold my-0 mt-2">Período para realização</p>
            <p class="body-2 font-weight-medium pl-3 my-0">Abertura</p>
            <p class="body-2 my-0 pl-3">{{ item && item.starts_at_formated }}</p>
            <p class="body-2 font-weight-medium pl-3 my-0 mt-2">Fechamento</p>
            <p class="body-2 my-0 pl-3">{{ item && item.ends_at_formated }}</p>
            <p class="body-2 font-weight-medium pl-3 my-0 mt-2">Duração máxima</p>
            <p class="body-2 my-0 pl-3">{{ item && item.duration === '0m' ? 'Sem limite' : item && item.duration }}</p>
          </v-col>
          <v-col cols="12" md="6">
            <p class="body-2 font-weight-bold my-0 mt-2">Período de realização</p>
            <p class="body-2 font-weight-medium pl-3 my-0">Início</p>
            <p class="body-2 my-0 pl-3">{{ item && item.student_exam.started_at_formated }}</p>
            <p class="body-2 font-weight-medium pl-3 my-0 mt-2">Término</p>
            <p class="body-2 my-0 pl-3">{{ item && item.student_exam.ended_at_formated }}</p>
            <p class="body-2 font-weight-medium pl-3 my-0 mt-2">Tempo dedicado</p>
            <p class="body-2 my-0 pl-3">{{ item && item.student_exam.time_spent }}</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          text
          color="primary"
          @click="display = false"
        >Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </template>
</v-dialog>
</template>

<script>
export default {
  name: 'DialogDetails',
  props: {
    item: Object,
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    display: {
      get () {
        return this.show
      },
      set (value) {
        this.$emit('show', value)
        return value
      }
    }
  }
}
</script>
