<template>
    <div class="my-6 tw-bg-gray-200 tw-p-4 tw-rounded-md">Resposta: {{ answer }}</div>
</template>

<script>
export default {
  props: ['wordings'],
  computed: {
    // this method return the answer of the question
    answer () {
      return this.wordings.data.answer.data.answers.length > 0
        ? this.wordings.data.answer.data.answers[0]
        : 'Não respondeu a questão'
    }
  }
}
</script>
