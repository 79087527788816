<template>
  <div id="materialpro" class="full-sidebar">
    <router-view />
    <base-alerts />
    <base-loading />
  </div>
</template>

<script>
import '@/assets/tailwind.css'
import { mapMutations } from 'vuex'

export default {
  name: 'App',
  data () {
    return {
      isOnLine: navigator.onLine
    }
  },
  mounted () {
    window.addEventListener('online', () => { this.isOnLine = true })
    window.addEventListener('offline', () => { this.isOnLine = false })
  },
  methods: {
    ...mapMutations('network', ['SET_ONLINE'])
  },
  watch: {
    isOnLine (newValue, oldValue) {
      if (newValue !== oldValue) this.SET_ONLINE(newValue)
    }
  }
}
</script>
