function getMarker (value) {
  switch (value) {
    case 'UPPER_ALPHA':
      return 'upper-alpha'
    case 'LOWER_ALPHA':
      return 'lower-alpha'
    case 'DECIMAL':
      return 'decimal'
    case 'UPPER_ROMAN':
      return 'upper-roman'
    case 'LOWER_ROMAN':
      return 'lower-roman'
    default:
      return 'none'
  }
}

export { getMarker }
