<template>
    <v-rating
        v-model="value"
        @input="update"
        empty-icon="mdi-star-outline"
        full-icon="mdi-star"
        hover
        :length="lenght"
        color="yellow darken-3"
        background-color="grey darken-1"
    ></v-rating>
</template>

<script>
export default {
  name: 'RatingQuestion',
  props: ['value', 'params'],
  data: () => ({
    lenght: Number
  }),
  created () {
    this.lenght = parseInt(this.params.length)
  },
  methods: {
    update () {
      this.$emit('input', this.value.toString())
    }
  }
}
</script>
