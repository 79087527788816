<template>
    <v-tooltip right >
        <template v-slot:activator="{ on, attrs }">
           <v-icon v-bind="attrs" v-on="on" :color="color" >{{ icon }}</v-icon>
        </template>
        <span>{{ title }}</span>
    </v-tooltip>
</template>

<script>
export default {
  name: 'TooltipStatus',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    }
  }
}
</script>
