const STATUS = [
  { code: 'NOT_STARTED', value: 'Não iniciado', color: 'default' },
  { code: 'STARTED', value: 'Iniciado', color: 'lime darken-3' },
  { code: 'CANCELLED', value: 'Cancelado', color: 'red' },
  { code: 'ENDED', value: 'Finalizado', color: 'primary' },
  { code: 'NEED_BE_CORRECTED', value: 'Precisa de correção', color: 'red darken-4' },
  { code: 'CORRECTED', value: 'Corrigido', color: 'primary' },
  { code: 'ANSWERS_FROM_BUBBLE_SHEET', value: 'Gabarito preenchido', color: 'primary' },
  { code: 'PUBLISHED', value: 'Publicado', color: 'light-green accent-4' },
  { code: 'AUTO_CORRECTED', value: 'Corrigida automaticamente', color: '' },
  { code: 'NOT_ANSWERED', value: 'Não respondida pelo aluno', color: '' }
]

export default STATUS
