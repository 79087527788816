export default () => ({
  examSelected: {
    student_exam_application: {
      data: {
        started_at: {},
        ended_at: null,
        courses: [],
        status: '',
        student: {}
      },
      metadata: { }
    },
    exam_application: {
      data: {
        questions_random_order: true,
        wordings_random_order: true,
        answers_random_order: true,
        status: '',
        marker: '',
        students_group: [],
        courses_group: [],
        starts_at: '',
        duration: 0,
        name: '',
        author: {
        },
        ends_at: '',
        instructions: ''
      },
      metadata: {
      }
    },
    exam: {
      data: {
        status: '',
        description: '',
        name: '',
        author: {
        }
      },
      metadata: {}
    },
    title: '',
    id: '',
    duration: '',
    starts_at_formated: '',
    ends_at_formated: '',
    url: ''
  },
  startedNotStartedExamsState: {
    items: [],
    pagination: {
      current_page: 1,
      last_page: 0,
      total: 0
    }
  },
  otherStatusExamsState: {
    items: [],
    pagination: {
      current_page: 1,
      last_page: 0,
      total: 0
    }
  }
})
