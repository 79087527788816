import api from '@/libs/axios'
import { format } from 'date-fns'

function stringContainsRequest (payload) {
  if (payload.includes('answerApplication') || payload.includes('logApplication')) { return payload }
}

// async function sendPost (request) {
//   await api.post(request.path, request.payload)
//     .then(response => {
//       console.log('action', formatExams(response.data, page))
//       commit('SET_EXAM_SELECTED', formatExams(response.data, page))
//       commit('SET_EXAM_LOADING', false)
//     })
// }
function uuidv4 () {
  // if ((typeof crypto) === 'object' && (typeof crypto.randomUUID()) !== 'undefined' && (typeof crypto.randomUUID()) === 'string') { return crypto.randomUUID() }
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

// console.log(uuidv4())
// export function ~
function storageRequests ({ commit }, { path, payload, order, type, answerId }) {
  localStorage.setItem(`${type || 'answerApplication'}/${answerId || uuidv4()}`, JSON.stringify({ path, payload, ...({ order }), when: Date.now() }))
}
function getStorageRequests () {
  let keys = Object.keys(localStorage)
  keys = keys.filter(stringContainsRequest)
  // console.log('keys'[keys])
  return keys
}
// logs
function sendLog ({ commit }, { organization, type, examApplication, studentExamApplication, exam, value, wording, question, when, until }) {
  const payload = {
    type,
    ...(when) && { when },
    ...(until) && { until },
    ...(value) && { value },
    ...(wording) && { wording },
    ...(question) && { question },
    exam_application: examApplication,
    student_exam_application: studentExamApplication,
    exam
  }
  const self = this
  const path = `/organizations/${organization}/students/exams-applications/${studentExamApplication}/log`
  api
    .post(path, payload)
    .then(response => {

    })
    .catch(error => {
      storageRequests({ commit }, { path, payload, type: 'logApplication' })

      const errosArray = Object.assign({}, error.response.data.errors)
      if (error.response.data.errors) {
        Object.keys(errosArray).forEach(function (item) {
          self.$store.dispatch('alert', {
            color: 'red',
            msg: errosArray[item][0]
          })
        })
      }
      if (error.response.data.message) {
        self.$store.dispatch('alert', { color: 'red', msg: error.response.data.message })
      } else {
        self.$store.dispatch('alert', {
          color: 'red',
          msg: 'Erro na rede, tente novamente!'
        })
      }
      // console.log(error.data)
    })
    .finally(() => {
    })
}

export default {
  storageRequests,
  getStorageRequests,
  sendLog
}
