<template>
  <v-card-text>
    <v-row align="center" justify="center">
      <v-btn-toggle v-model="toggle_exclusive">
        <v-btn @click="$emit('prev')" :disabled="disablePrev">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn @click="$emit('next')" :disabled="disableNext">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-row>
  </v-card-text>
</template>
<script>
export default {
  props: {
    disablePrev: {
      type: Boolean,
      default: true
    },
    disableNext: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    toggle_exclusive: undefined
  })
}
</script>
