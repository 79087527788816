<template>
    <div>
        <div v-for="option in optionsFiltered" :key="option.id">
          <v-card elevation="2" class="d-flex pa-4 mb-3">
            <v-row>
              <v-col cols=12>
                <v-checkbox
                  v-model="responses[option.id]"
                  class="pe-2 flex align-center"
                  @change="handleInput($event)"
                />
              </v-col>
              <v-col cols=12>
                <p class="pa-5" v-html="option.answer" />
              </v-col>
            </v-row>
          </v-card>
        </div>
    </div>
</template>

<script>
import collect from 'collect.js'
import { convertHtmlToText } from '../../utils/helpers'

export default {
  name: 'MultipleChoiceMultipleAnswerQuestion',
  props: ['value', 'wordings'],
  data () {
    return {
      options: [],
      responses: {}
    }
  },
  computed: {
    optionsFiltered () {
      return collect(this.options)
        .values()
        .map(option => {
          const regex = /<iframe[^>]*src=["|']([^'"]+)["|'][^>]*>/g
          const answerFormated = this.formatAnswer(option.answer)
          const answer = option.answer.replace(regex, answerFormated)
          return { ...option, answer }
        })
        .all()
    }
  },
  created () {
    this.options = collect(this.wordings.options).toArray()
    collect(this.value).map(item => {
      this.responses[item] = true
    })
  },
  methods: {
    convertHtmlToText,
    handleInput (e) {
      const answers = collect(this.responses).map((value, index) => {
        if (value) {
          return index
        }
        return null
      }).values().whereNotNull().all()
      this.$emit('input', answers)
    },
    formatAnswer (answer) {
      const iFrameRegex = /<iframe[^>]*src=["|']([^'"]+)["|'][^>]*>/g
      const widthRegex = /width=\S+/g
      const heightRegex = /height=\S+/g
      const match = answer.match(iFrameRegex)
      if (!match) {
        return
      }
      return match.toString()
        .replace(widthRegex, 'width="500"')
        .replace(heightRegex, 'height="350"')
        .replace('allowfullscreen="allowfullscreen"', '')
    }
  }
}
</script>
