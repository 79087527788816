
// const REMOVE_SELECTED = (state, item) => {
//   const index = state.selectedQuestions.findIndex(x => x.id === item.id)
//   state.selectedQuestions.splice(index, 1)
// }

// examSelected
const SET_EXAM_SELECTED = (state, item) => {
  state.examSelected = item
}

const SET_EXAMS_LIST = (state, payload) => {
  state.examsList = payload
}
const SET_STARTED_NOT_STARTED_EXAMS_LIST = (state, payload) => {
  state.startedNotStartedExamsState = payload
}
const SET_OTHER_STATUS_EXAMS = (state, payload) => {
  state.otherStatusExamsState = payload
}

export default {
  SET_EXAM_SELECTED,
  SET_STARTED_NOT_STARTED_EXAMS_LIST,
  SET_EXAMS_LIST,
  SET_OTHER_STATUS_EXAMS
}
