import api from '@/libs/axios'

function formatWordings (object) {
  if (object) {
    return Object.keys(object).map(id => {
      const data = object[id]
      data.id = id
      return data
    })
  }
}
function formatQuestion (questions) {
  const arr = []
  let question
  questions.forEach(element => {
    if (element.data.wordings && element.data.wordings[0] &&
      element.data.wordings[0].data.type === 'MULTIPLE_CHOICE' &&
      element.data.wordings[0].data.options) {
      question = {
        ...element,
        data: {
          ...element.data,
          wordings: [
            {
              ...element.data.wordings[0],
              data: {
                ...element.data.wordings[0].data,
                options: formatWordings(element.data.wordings[0].data.options)
              }
            }
          ]
        },
        id: element.data.wordings[0].metadata.id
      }
    } else {
      question = {
        ...element,
        id: element.metadata.id
      }
    }
    arr.push(question)
  })
  return arr
}
function formatExams (question, page) {
  if (!question) return {}
  const obj = {
    show: true,
    ...question.data,
    checked: false,
    title: question.data.name,
    duration: question.data.duration,
    id: question.metadata.id,
    question: formatQuestion(question.data.question),
    permissions: { ...question.permissions },
    pagination: { ...question.pagination }
    // permissions: {
    //   create: true,
    //   update: true,
    //   delete: true
    // },
    // pagination: {
    //   current_page: page,
    //   per_page: 10,
    //   prev_page_url: false,
    //   next_page_url: false
    // }
  }
  return obj
}

const getExam = async ({ commit }, { id, page = 1 }) => {
  if (!id) return
  commit('SET_EXAM_LOADING', true)
  await api.get(`/exams/${id}?with_questions=1&with_wordings=1&page=${page}`)
    .then(response => {
      commit('SET_EXAM_SELECTED', formatExams(response.data, page))
      commit('SET_EXAM_LOADING', false)
    })
}

async function fetchstartedNotStartedExams ({ commit }, { id, page = 1 }) {
  try {
    const status = '?statuses[]=STARTED&statuses[]=NOT_STARTED'
    const orderBy = '&order_by[0][column]=exam_application.starts_at&order_by[0][direction]=DESC'
    const url = `/organizations/${id}/students/exams-applications${status}${orderBy}&page=${page}`
    const response = await api.get(url)
    commit('SET_STARTED_NOT_STARTED_EXAMS_LIST', response.data)
  } catch (e) {
    console.error(e)
  }
}

async function fetchPreviousExams ({ commit }, { id, page = 1 }) {
  try {
    const status = 'statuses[]=ENDED&statuses[]=NEED_BE_CORRECTED&statuses[]=CORRECTED&statuses[]=ANSWERS_FROM_BUBBLE_SHEET&statuses[]=PUBLISHED'
    const orderBy = '&order_by[0][column]=exam_application.starts_at&order_by[0][direction]=DESC'
    const url = `/organizations/${id}/students/exams-applications?${status}${orderBy}&page=${page}`
    const response = await api.get(url)
    commit('SET_OTHER_STATUS_EXAMS', response.data)
  } catch (e) {
    console.error(e)
  }
}

export default {
  getExam,
  fetchstartedNotStartedExams,
  fetchPreviousExams
}
