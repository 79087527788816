var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"contact-listing-app",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"hide-default-header":true,"items":_vm.items,"sort-by":_vm.sortBy,"search":_vm.search,"show-select":_vm.showSelect},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","height":"auto"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.text.searchLabel,"filled":"","background-color":"transparent","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"d-block":"","cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.authors,"background-color":"transparent","label":"Autores","filled":"","multiple":""}})],1)],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"to":_vm.text.addUrl,"icon":""}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.text.addLabel))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"to":_vm.text.importUrl,"icon":""}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-cloud-upload-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.text.importLabel))])]),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.actionItems),function(item,i){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(!item.needSelect || (item.needSelect && _vm.showSelect)),expression:"!item.needSelect || (item.needSelect && showSelect)"}],key:i},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-3"},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1)}),1)],1)],1)]},proxy:true},{key:"item.question",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-12 py-0"},[_vm._v(_vm._s(item.question))]),_c('div',{staticClass:"col-12 subtitle-2 font-weight-regular py-0"},[_vm._v(_vm._s(item.details))]),_c('div',{staticClass:"d-none"},[_vm._v(_vm._s(item.author))])]}},{key:"no-data",fn:function(){return [_vm._v(_vm._s(_vm.text.emptyLabel))]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }