var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-2"},[_c('div',{staticClass:"px-4 pt-4"},[_c('v-chip',{attrs:{"small":"","dark":"","label":"","color":_vm.item.student_exam.status_color}},[_vm._v(" "+_vm._s(_vm.item.student_exam.status_formated)+" ")])],1),_c('v-card-title',{staticClass:"application-title text-break mb-0 pb-0 pt-0",staticStyle:{"max-width":"100%"},domProps:{"textContent":_vm._s(_vm.item.title)}}),_c('v-card-text',{staticClass:"pt-3 pb-0"},[_c('p',{staticClass:"font-weight-medium body-1 mb-0 tw-text-gray-700"},[_vm._v("Resumo")]),_c('ul',{staticClass:"details tw-text-sm tw-block text-truncate"},[_c('li',{staticClass:"d-flex mb-1"},[_vm._v("Abertura: "+_vm._s(_vm.item.starts_at_formated))]),_c('li',{staticClass:"d-flex mb-1"},[_vm._v("Fechamento: "+_vm._s(_vm.item.ends_at_formated))])]),_c('div',{staticClass:"d-flex pt-2"},[_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"primary","size":"35"}},'v-avatar',attrs,false),on),[_c('img',{attrs:{"src":_vm.item.course.avatar}})])]}}])},[_c('span',[_vm._v(_vm._s(_vm.item.course.name))])])],1),_c('v-spacer'),_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"primary","size":"35"}},'v-avatar',attrs,false),on),[_c('img',{attrs:{"src":_vm.item.author.avatar}})])]}}])},[_c('span',[_vm._v(_vm._s(_vm.item.author.name))])])],1)],1)]),_c('v-card-actions',{staticClass:"d-flex justify-space-around"},[_c('v-btn',{attrs:{"text":"","color":"primary","width":"40%"},on:{"click":function($event){return _vm.$emit('select')}}},[_vm._v("Mais detalhes")]),(
        ['STARTED', 'NOT_STARTED'].includes(_vm.item.student_exam.status)
        && !this.isPreviousDate(_vm.item.data.exam_application.ends_at))?_c('v-btn',{attrs:{"color":"primary","outlined":"","width":"40%"},on:{"click":function($event){return _vm.$emit('setExam')}}},[_vm._v(" "+_vm._s(_vm.item.student_exam.status === 'STARTED' ? 'Retomar' : 'Iniciar')+" ")]):_vm._e(),(['PUBLISHED'].includes(_vm.item.student_exam.status))?_c('v-btn',{attrs:{"text":"","color":"primary","width":"40%"},on:{"click":function($event){return _vm.$emit('reviewPastApplication')}}},[_vm._v("Ver correção")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }