<template>
    <v-card class="text-center mt-5" outlined elevation="0">
        <v-card-title class="justify-center blue darken-1 white--text py-2 mb-4">Informações adicionais</v-card-title>
        <v-card-text style="font-size: 15px">
            <p class="body-2 my-0 font-weight-medium">Status da questão</p>
            <p class="body-2 mb-5">
             {{ getNameStatus(question.studentAnswer.status) }}
            </p>
            <p class="body-2 my-0 font-weight-medium">Tempo gasto na resolução</p>
            <p class="body-2 mb-5">
              <v-icon class="body-2">mdi-alarm</v-icon>{{ formatSeconds(question.studentAnswer.time_spent) }}
            </p>
            <v-divider></v-divider>
            <div v-if="question.studentAnswer.notes">
              <p class="body-2 my-0 font-weight-medium">Observação do professor</p>
              <p class="body-2 mb-5">
                <v-icon class="body-2">mdi-message-text-outline</v-icon> {{ question.studentAnswer.notes }}
              </p>
            </div>
        </v-card-text>
      </v-card>
</template>

<script>
import { getNameStatus } from '../../utils/helpers'
import { format } from 'date-fns'

export default {
  props: ['question'],
  methods: {
    getNameStatus,
    // Format seconds to minutes and seconds
    formatSeconds (seconds) {
      return format(new Date(seconds * 1000), 'mm:ss')
    }
  }
}
</script>
