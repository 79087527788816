<template>
    <v-slider
    :value="answer"
    :max="params.max"
    :min="params.min"
    readonly
    thumb-label="always"
    />
</template>

<script>
export default {
  name: 'SliderPreview',
  props: ['value', 'params'],
  computed: {
    answer () {
      return parseInt(this.value[0]) || this.params.main
    }
  }
}
</script>
