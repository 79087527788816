<template>
  <div>
    <v-textarea
      v-if="!params.is_short_answer"
      v-model="inputValue"
      @input="handleInput($event)"
      label="Resposta"
      :counter="limitValue ? limitValue : 0"
      outlined
      dense
      :hint="hint"
      persistent-hint
      full-width
      single-line
      ref="textArea"
      :maxlength=limitValue
      >
      <template slot="append-outer">
        <v-icon @click="$emit('copyText')" class="tw-cursor-pointer mx-3">mdi-content-copy</v-icon>
        <v-icon @click="$emit('pasteText')" class="tw-cursor-pointer">mdi-content-paste</v-icon>
      </template>
    </v-textarea>
    <v-text-field
      v-if="params.is_short_answer"
      v-model="inputValue"
      @input="handleInput($event)"
      dense
      outlined
      :counter="limitValue ? limitValue : 0"
      :maxlength=limitValue
    />
  </div>
</template>

<script>
export default {
  name: 'TextAreaQuestion',
  props: ['hint', 'value', 'params'],
  data: () => ({
    inputValue: ''
  }),
  mounted () {
    this.inputValue = this.value
  },
  methods: {
    handleInput (e) {
      if ((this.params.is_limited || this.params.is_short_answer) && (e.length > this.limitValue - 1)) {
        this.inputValue = this.inputValue.slice(0, (this.limitValue))
      }
      this.$emit('input', this.inputValue)
    }
  },
  computed: {
    limitValue () {
      return this.params.limit_value === 0 ? null : this.params.limit_value
    }
  }
}

</script>
