const organizations = state => state.organizations
const activeOrganization = state => state.activeOrganization

const getActiveOrganizationDomains = state => {
  if (state.organizations.length === 1) {
    state.activeOrganization = state.organizations[0].metadata.id
  }

  if (state.activeOrganization) {
    return state.organizations.find(organization => organization.metadata.id === state.activeOrganization)?.data?.domains
  }

  return null
}

export default {
  organizations,
  activeOrganization,
  getActiveOrganizationDomains
}
