import Vue from 'vue'
import App from './views/pages/AppPruvo.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import VueFullscreen from 'vue-fullscreen'
// import * as Sentry from '@sentry/vue'
// import { BrowserTracing } from '@sentry/tracing'

// icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// mask
import { VueMaskDirective, VueMaskFilter } from 'v-mask'

Vue.use(VueFullscreen)
Vue.directive('mask', VueMaskDirective)
Vue.filter('VMask', VueMaskFilter)

// import { faFontAwesome } from '@fortawesome/free-brands-svg-icons'

library.add(faUserSecret)
// library.add(faFontAwesome)
Vue.component('font-awesome-icon', FontAwesomeIcon)
// icons
Vue.config.productionTip = false

const requireComponent = require.context(
  './components',
  true, // deep search
  /[A-Z]\w+\.(vue|js)$/
)
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(fileName.split('/').pop().replace(/\.\w+$/, ''))
  )

  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  )
})

// Sentry.init({
//   Vue,
//   dsn: 'https://101b329ee4e34b04ab3e821b26ff86f8@o1138815.ingest.sentry.io/6202217',
//   integrations: [
//     // ,
//     // new BrowserTracing({
//     //   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     //   tracingOrigins: ['localhost', 'aluno.pruvo.app', /^\//]
//     // })
//   ],
//   environment: process.env.NODE_ENV,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.1
// })

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
