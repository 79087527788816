<template>
    <v-slider
    v-model="value"
    :max="params.max"
    :min="params.min"
    :step="params.step"
    thumb-label="always"
    @change="update"
    ></v-slider>
</template>

<script>
export default {
  name: 'SliderQuestion',
  props: ['value', 'params'],
  methods: {
    update () {
      this.$emit('input', this.value.toString())
    }
  }
}
</script>
