<template>
  <v-card class="ma-2">
    <div class="px-4 pt-4">
      <v-chip
        small
        dark
        label
        :color="item.student_exam.status_color"
      >
      {{ item.student_exam.status_formated }}
      </v-chip>
    </div>
    <v-card-title class="application-title text-break mb-0 pb-0 pt-0" style="max-width: 100%;" v-text="item.title" />
    <v-card-text class="pt-3 pb-0">
      <p class="font-weight-medium body-1 mb-0 tw-text-gray-700">Resumo</p>
      <ul class="details tw-text-sm tw-block text-truncate">
        <li class="d-flex mb-1">Abertura: {{item.starts_at_formated}}</li>
        <li class="d-flex mb-1">Fechamento: {{item.ends_at_formated}}</li>
      </ul>
      <div class="d-flex pt-2">
        <div>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar color="primary" size="35" v-bind="attrs" v-on="on">
                <img :src="item.course.avatar" />
              </v-avatar>
            </template>
            <span>{{ item.course.name }}</span>
          </v-tooltip>
        </div>
        <v-spacer />
        <div>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar color="primary" size="35" v-bind="attrs" v-on="on">
                <img :src="item.author.avatar" />
              </v-avatar>
            </template>
            <span>{{ item.author.name }}</span>
          </v-tooltip>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-space-around">
      <v-btn
        text
        color="primary"
        width="40%"
        @click="$emit('select')"
      >Mais detalhes</v-btn>
      <v-btn
        color="primary"
        outlined
        width="40%"
        v-if="
          ['STARTED', 'NOT_STARTED'].includes(item.student_exam.status)
          && !this.isPreviousDate(item.data.exam_application.ends_at)"
        @click="$emit('setExam')"
      >
        {{ item.student_exam.status === 'STARTED' ? 'Retomar' : 'Iniciar' }}
      </v-btn>
      <v-btn
        text
        color="primary"
        width="40%"
        v-if="['PUBLISHED'].includes(item.student_exam.status)"
        @click="$emit('reviewPastApplication')"
      >Ver correção</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { parseISO, compareAsc } from 'date-fns'

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {}
  },
  methods: {
    isPreviousDate (date) {
      if (!date) return false
      if (compareAsc(new Date(Date.now()), parseISO(date)) === -1) {
        return false
      }
      return true
    }
  }
}
</script>
