<template>
    <v-row>
        <v-col class="tw-overflow-x-auto">
          <table class="my-10 tw-w-full">
            <tr>
              <th></th>
              <th class="text-center" v-for="column, columnIndex in columns" :key="columnIndex">
                {{ column ? column.answer : `Resposta ${columnIndex}` }}
              </th>
            </tr>
            <tr v-for="row, rowIndex in rows" :key="rowIndex">
              <td class="pa-3 text-center max-width-columm">{{ row ? row.value : `Pergunta ${rowIndex}` }}</td>
              <td class="tw-mx-auto" v-for="(column, columnIndex) in row.options" :key="columnIndex">
                <v-radio-group v-model="row.answer"  @change="updateAnswer(row, column.id)">
                  <v-radio class="tw-flex tw-justify-center" :key="columnIndex" :value="columnIndex">
                  </v-radio>
                </v-radio-group>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
</template>

<script>
import collect from 'collect.js'

export default {
  name: 'MatrixQuestion',
  props: ['wordings'],
  data: () => ({
    columns: [],
    rows: []
  }),
  created () {
    this.rows = collect(this.wordings).map((row, index) => {
      return {
        value: row.wording,
        id: index,
        options: row.options,
        answer: typeof row.answer === typeof [] ? row.answer[0] : row.answer
      }
    }).toArray()
    const options = collect(this.wordings).first().options
    this.columns = collect(options).map((option, index) => {
      return {
        answer: option.answer,
        id: index
      }
    }).toArray()
  },
  methods: {
    updateAnswer (row, answerId) {
      row.answer = answerId
      this.$emit('change', this.rows)
    }
  }
}
</script>

<style scoped>
.max-width-columm {
  word-wrap: break-word;
  max-width: 9rem;
}
</style>
