<template>
  <v-row>
    <v-col class="tw-overflow-x-auto">
      <table class="my-10">
        <tr>
          <th></th>
          <template>
            <th class="text-center pa-3" v-for="column in columns" :key="column.key">
              {{ column.answer }}
            </th>
          </template>
        </tr>
        <tr >
          <template>
            <td class="pa-3 text-center">{{ question.wordings.data.wording }}</td>
            <template>
              <td v-for="row in columns" :key="row.key">
                <v-radio-group v-model="answer">
                  <v-radio disabled class="tw-flex tw-justify-center" :key="row.key" :value="row.key">
                  </v-radio>
                </v-radio-group>
              </td>
            </template>
          </template>
        </tr>
      </table>
    </v-col>
  </v-row>
</template>

<script>
import collect from 'collect.js'

export default {
  name: 'MatrixPreview',
  props: ['question'],
  computed: {
    answer () {
      return collect(this.question.studentAnswer.answers)
        .whereNotNull()
        .first()
    },
    columns () {
      return collect(this.question.wordings.data.options)
        .keys()
        .map(key => {
          return {
            key: key,
            ...this.question.wordings.data.options[key]
          }
        })
        .all()
    }
  }
}
</script>
