<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="itemsComputed"
            :items-per-page="10"
            class="elevation-1 px-5"
            @click:row="openQuestion($event)">
            <template v-slot:item.title="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                  {{ item.title.length > 50 ? `${item.title.substring(0, 50)}...` : item.title }}
                  </div>
                </template>
                <span>Clique para visualizar a questão</span>
              </v-tooltip>
            </template>
            <template v-slot:item.status="{ item }">
              <TooltipStatus icon="mdi-comment-check-outline" color="green" title="Questão correta"  v-if="parseInt(item.studentAnswer.final_grade) === parseInt(item.wordings.data.grade) "/>
              <TooltipStatus icon="mdi-comment-bookmark-outline" color="blue" title="Questão pontuada"  v-else-if="item.studentAnswer.final_grade > 0"/>
              <TooltipStatus icon="mdi-comment-edit-outline" color="orange" title="Questão precisa de correção"  v-else-if="item.studentAnswer.status === 'NEED_BE_CORRECTED'"/>
              <TooltipStatus icon="mdi-close" color="red" title="Questão cancelada"  v-else-if="item.studentAnswer.status === 'CANCELED'"/>
              <TooltipStatus icon="mdi-comment-remove-outline" color="red" title="Questão incorreta"  v-else-if="item.studentAnswer.answers.length"/>
              <TooltipStatus icon="mdi-comment-off-outline" title="Questão não respondida"  v-else-if="item.studentAnswer.status === 'NOT_ANSWERED'"/>
            </template>
        </v-data-table>
        <ResultDialogQuestion v-model="questionDialog" :question=actualQuestion />
    </div>
</template>

<script>
import ResultDialogQuestion from '@/components/result/ResultDialogQuestion.vue'
import { convertHtmlToText } from '@/utils/helpers'
import collect from 'collect.js'

export default {
  name: 'ResultQuestionsList',
  components: { ResultDialogQuestion },
  props: ['headers', 'items'],
  data: () => ({
    questionDialog: false,
    actualQuestion: {}
  }),
  methods: {
    convertHtmlToText,
    openQuestion (e) {
      this.actualQuestion = e
      this.questionDialog = true
    }
  },
  computed: {
    itemsComputed () {
      const items = []
      collect(this.items)
        .each(item => {
          collect(item.data.wordings).each(wording => {
            items.push({
              ...item.data,
              title: wording.data.wording ? wording.data.wording : this.convertHtmlToText(item.data.main_text),
              wordings: wording,
              score: wording.data.answer.data.final_grade,
              studentAnswer: wording.data.answer.data
            })
          })
        })
      return items
    }
  }
}
</script>
