<template>
  <v-container class="pa-4 question">
    <div class="mb-6" v-html="item.main_text"></div>
    <div class="mb-6" v-html="item.wordings[0].wording"></div>
    <div v-if="item.wordings[0].type === 'MULTIPLE_CHOICE'">
      <multiple-choice-question
       v-model="item.wordings[0].answer"
       @change="answerQuestion"
       :application="application"
       :item="item"
      />
    </div>
    <div v-if="item.wordings[0].type === 'OPEN_ENDED'">
    <text-area-question
      v-model="item.wordings[0].answer"
      :hint="saveAnswer"
      @copyText="copyText()"
      @pasteText="pasteText()"
      :params="item.wordings[0].params"
      />
    </div>
    <div v-if="item.wordings[0].type === 'SLIDER'">
      <slider-question
        v-model="item.wordings[0].answer"
        :params="item.wordings[0].params"
        />
    </div>
    <div v-if="item.wordings[0].type === 'MATRIX'">
      <matrix-question
      :wordings="item.wordings"
      @change="answerQuestion"
      />
    </div>
    <div v-if="item.wordings[0].type === 'RATING'">
      <rating-question
        v-model="item.wordings[0].answer"
        :params="item.wordings[0].params"
      />
    </div>
    <div v-if="item.wordings[0].type === 'MULTIPLE_CHOICE_MULTIPLE_ANSWER'">
      <multiple-choice-multiple-answer-component
      v-model="item.wordings[0].answer"
      :wordings="item.wordings[0]"
      />
    </div>
  </v-container>
</template>
<script>
import { format } from 'date-fns'
import TextAreaQuestion from '../question-type/TextAreaQuestionComponent.vue'
import MultipleChoiceQuestion from '../question-type/MultipleChoiceQuestionComponent.vue'
import SliderQuestion from '../question-type/SliderQuestionComponent.vue'
import MatrixQuestion from '../question-type/MatrixQuestionComponent.vue'
import RatingQuestion from '../question-type/RatingQuestionComponent.vue'
import MultipleChoiceMultipleAnswerComponent from '../question-type/MultipleChoiceMultipleAnswerComponent.vue'

export default {
  components: {
    TextAreaQuestion,
    MultipleChoiceQuestion,
    SliderQuestion,
    MatrixQuestion,
    RatingQuestion,
    MultipleChoiceMultipleAnswerComponent
  },
  props: {
    application: {
      type: Object,
      require: true
    },
    item: {
      type: Object,
      require: true
    },
    index: {
      type: Number,
      require: true
    },
    resumeQuestions: {
      type: Object
    }

  },
  data: () => ({
    main: '',
    lastUpdate: null
  }),
  beforeMounted () {
    this.reRender()
  },
  watch: {
    index: {
      immediate: true,
      async handler () {
        await this.reRender()
      }
    }
  },
  computed: {
    saveAnswer () {
      if (!this.resumeQuestions.lastSyncAnswer) return
      const result = format(new Date(this.resumeQuestions.lastSyncAnswer), 'HH:mm:ss')
      return `Salvo às ${result}`
      // ${this.$moment().format('LLL')}
    }
  },
  methods: {
    reRender () {
      if (window.MathJax) {
        // rendering mathjax
        setTimeout(() => {
          const elements = document.getElementsByClassName('math-tex')

          // done
          window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub])

          if (elements.length) {
            for (const id in elements) {
              // console.log(elements[id])
              elements[id].style.cssText += ';display:block;'
              // elements[id].style.display = 'block'
            }
          }
        }, 1000)
      }
    },
    async answerQuestion (e = null) {
      this.$emit('sendAnswer', e)
    },
    async copyText () {
      await navigator.clipboard.writeText(this.item.wordings[0].answer)
    },
    async pasteText () {
      const text = await navigator.clipboard.readText()
      this.item.wordings[0].answer = text
    }
  }
}
</script>
<style>
div#MathJax_Message {
    display: none!important;
}
.question table td {
  border: 1px solid !important;
}
.math-tex {
  display: none;
}
.question .option p {
  margin: 0!important;
}

</style>
<style scoped>
.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 2px;
}
</style>
