<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :hide-default-header="true"
    :items="items"
    :sort-by="sortBy"
    class="contact-listing-app"
    :search="search"
    :show-select="showSelect"
    @click:row="handleClick"
    style="cursor: pointer"
  >
    <template v-slot:top>
      <v-toolbar flat height="auto">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="text.searchLabel"
              filled
              background-color="transparent"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col d-block cols="12" md="6">
            <v-select
              :items="authors"
              background-color="transparent"
              label="Autores"
              filled
              multiple
            ></v-select>
          </v-col>
        </v-row>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :to="text.addUrl" icon>
              <v-icon v-bind="attrs" v-on="on">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ text.addLabel }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :to="text.importUrl" icon>
              <v-icon v-bind="attrs" v-on="on">mdi-cloud-upload-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ text.importLabel }}</span>
        </v-tooltip>

        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-show="!item.needSelect || (item.needSelect && showSelect)"
              v-for="(item, i) in actionItems"
              :key="i"
            >
              <v-list-item-title>
                <v-icon class="mr-3">{{ item.icon }}</v-icon>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.question="{ item }">
      <div class="col-12 py-0">{{ item.question }}</div>
      <div class="col-12 subtitle-2 font-weight-regular py-0">{{ item.details }}</div>
      <div class="d-none">{{ item.author }}</div>
    </template>

    <template v-slot:no-data>{{ text.emptyLabel }}</template>
  </v-data-table>
</template>
<script>
export default {
  props: {
    text: {},
    headers: {},
    sortBy: {},
    items: {},
    showSelected: {},
    selected: {},
    showSelect: {}
  },
  data: () => ({
    actionItems: [
      {
        title: 'Remover Seleção',
        icon: 'mdi-delete-outline',
        needSelect: true
      },
      { title: 'Exportar (.pdf)', icon: 'mdi-file-pdf-outline' },
      { title: 'Exportar (.xlsx)', icon: 'mdi-file-excel-outline' },
      { title: 'Exportar (.csv)', icon: 'mdi-file-delimited-outline' }
    ],
    dialog: false,
    singleSelect: false,
    search: '',
    authors: ['Todas', 'Minhas Questões', 'Questões da Institução']
  }),

  methods: {
    handleClick (value) {
      this.$router.push({ path: value.url })
    }
  }
}
</script>
