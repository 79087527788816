import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import VueGtag from 'vue-gtag'
import api from '@/libs/axios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: () => import('../views/pages/authentication/Login.vue')
  },
  {
    path: '/register',
    component: () => import('../views/pages/authentication/Register.vue')
  },
  {
    path: '/password/forgot',
    component: () => import('../views/pages/authentication/password/Forgot.vue')
  },
  {
    path: '/password/reset',
    component: () => import('../views/pages/authentication/password/RegisterNew.vue')
  },
  {
    path: '/permission',
    component: () => import('../views/pages/authentication/Permission.vue')
  },
  {
    path: '/',
    redirect: '/organization',
    component: () => import('../views/layouts/MainLayout.vue'),
    async beforeEnter (to, from, next) {
      const token = localStorage.getItem('jwtToken')
      if (!token) {
        store.dispatch('setDataAuth', {})
        store.dispatch('organization/clearOrganization')
        store.commit('setLoading', false)
        return next('/login')
      }
      try {
        const res = await api.post('/auth/me')
        const { success, data } = res.data
        if (success) {
          // eslint-disable-next-line camelcase
          const { acl, is_super_admin } = data
          await store.dispatch('setDataAuth', { token, meData: res.data })
          // eslint-disable-next-line camelcase
          if (is_super_admin || acl.filter(value => value.roles.includes('STUDENT')).length > 0) {
            await store.dispatch('organization/fetchOrganizations')
          } else {
            store.dispatch('alert', { color: 'red', msg: 'Seu usuário não pertence a nenhuma turma como aluno.' })
            return next('/permission')
          }
          store.commit('setLoading', false)
          return next()
        }
        const msg = 'Erro interno: Verifique suas permissões e/ou se seu usuário está em uma organização'
        store.dispatch('alert', { color: 'red', msg })
        return next('/login')
      } catch (err) {
        if (err.request && err.request.status === 401) {
          const needAuthentication = 'Área restrita: realize login para acessar.'
          store.dispatch('alert', { color: 'red', msg: needAuthentication })
          store.commit('setLoading', false)
          return next('/login')
        }
        store.dispatch('alert', { color: 'red', msg: 'Erro. Por favor atualize a página e tente novamente.' })
        store.commit('setLoading', false)
        return next()
      }
    },
    children: [
      {
        name: 'List Organization',
        path: 'organization',
        component: () => import('../views/organization/OrganizationList.vue')
      },
      {
        name: 'Resolution List',
        path: 'resolution',
        redirect: 'resolution/list'
      },
      {
        name: 'Application Resolution list',
        path: 'resolution/list',
        component: () => import('../views/resolution/ResolutionList.vue')
      },
      {
        name: 'Application Resolution view',
        path: 'resolution/view/:id',
        component: () => import('../views/resolution/ResolutionView.vue')
      },
      {
        name: 'Application Resolution Edit',
        path: 'application/resolution/edit/:id',
        component: () => import('../views/application/resolution/ResolutionEdit.vue')
      },
      {
        name: 'Result Application View',
        path: 'result/exams/:examid/applications/:applicationid/students/:studentid',
        component: () => import('../views/result/ResultView.vue')
      },
      {
        name: 'Support Material List',
        path: 'support-material',
        component: () => import('../views/support-material/SupportMaterialView.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Vue.use(VueGtag, {
  config: { id: 'G-49LLZBZZS1' }
}, router)

router.beforeEach((to, from, next) => {
  store.dispatch('setFirstLoading', true)
  next()
})

router.afterEach((to, from, next) => {
  setTimeout(() => store.dispatch('setFirstLoading', false), 500)
})

export default router
