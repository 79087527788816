import collect from 'collect.js'
import STATUS from '@/constants/status'

function convertHtmlToText (value, imageText = '') {
  const response = value.replace(/<img([\w\W]+?)>/g, imageText)
  return response.replace(/&(nbsp);|<[^>]*>?/g, '')
}

function getNameStatus (value) {
  return collect(STATUS)
    .where('code', value)
    .first()
    .value
}

export { convertHtmlToText, getNameStatus }
