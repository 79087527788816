var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"contact-listing-app",class:[_vm.tableUser ? 'table-user' : 'table-data'],staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":_vm.sortBy,"search":_vm.search,"show-select":_vm.showSelect,"hide-default-footer":""},on:{"click:row":_vm.handleClick,"toggle-select-all":_vm.selectAllToggle},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center flex-column flex-sm-row tw-w-full",class:_vm.isSelected ? 'selected' : ''},[_c('div',{staticClass:"mr-4"},[(item && item.data.company.logo)?_c('v-img',{staticClass:"rounded-circle img-fluid",attrs:{"src":item.data.company.logo,"width":_vm.$vuetify.breakpoint.xs ? '80px' : '45px'}}):_vm._e()],1),_c('div',{staticClass:"tw-text-xl sm:tw-text-lg tw-mb-3 sm:mb-0"},[_vm._v(" "+_vm._s(item.data.company.name.legal)+" "),(item.data.company.name.trade)?_c('span',{staticClass:"subtitle-2 font-weight-regular d-block",class:{'text-no-wrap': !_vm.$vuetify.breakpoint.xs}},[_vm._v(_vm._s(item.data.company.name.trade))]):_vm._e()])])]}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [(item.selectDisabled)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"readonly":item.selectDisabled,"disabled":item.selectDisabled},on:{"input":function($event){return select($event)}}})]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.disabledDescription || ''))])])]:[_c('v-simple-checkbox',{attrs:{"value":isSelected},on:{"input":function($event){return select($event)}}})]]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$vuetify.breakpoint.xs ? 'tw-w-full tw-text-2xl tw-py-2 tw-text-center': ''},[_vm._v(" "+_vm._s(item.title)+" "),(item.subtitle)?_c('span',{staticClass:"subtitle-2 font-weight-regular d-block",class:{'text-no-wrap': !_vm.$vuetify.breakpoint.xs}},[_vm._v(" "+_vm._s(item.subtitle)+" ")]):_vm._e()])]}},{key:"no-data",fn:function(){return [_vm._v(_vm._s(_vm.text.emptyLabel))]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }