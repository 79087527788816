<template>
  <v-row>
    <v-col>
      <v-radio-group
      v-model="value"
      @change="answerQuestion"
    >
      <ul :style="{ listStyleType: getMarker(application.data.exam_application.marker)}" class="pl-0">
        <div v-for="(item, name, index) in item.wordings[0].options" :key="`${index}-answer`">
          <v-radio
            :value="item.id"
            class="option-answer"
            active-class="selected-answer"
          >
            <template slot="label">
              <li
                :style="'list-style-type: ' + getMarker(application.data.exam_application.marker) "
                :class="(getMarker(application.data.exam_application.marker)!=='none') ? 'ml-6' : 'ml-2'"
              ></li>
              <span v-html="item.answer" class="body-2 radio--text option" ></span>
            </template>
          </v-radio>
        </div>
      </ul>
    </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
import { getMarker } from '../../utils/marker'

export default {
  name: 'MultipleChoiceQuestion',
  props: ['value', 'application', 'item'],
  methods: {
    getMarker,
    answerQuestion () {
      this.handleInput()
      if (this.value) {
        this.$emit('change')
      }
    },
    handleInput () {
      this.$emit('input', this.value)
    }
  }
}
</script>

<style scoped>
.option-answer {
    padding: 10px 5px !important;
    margin: 10px 0;
    border-radius: 5px;
    border: 2px solid rgb(177, 177, 177);
    background: rgb(243, 243, 243);
    transition: 0.5s;
}
.selected-answer {
    border: 2px solid #1e88e5;
    background: rgb(201, 224, 255);
    transition: 0.5s;
}
.radio--text {
    color: rgba(0, 0, 0, 0.87);
}
.v-application--is-ltr .v-input--selection-controls__input {
    margin-right: 2px;
}
</style>
