<template>
    <v-col cols="12" md="4" class="pt-0 pb-2 px-1">
        <application-card :item="item" @select="selectExam(item)" @setExam="setExam(item)">
        </application-card>
    </v-col>
</template>
<script>
import ApplicationCard from '@/components/cards/ApplicationCard.vue'
export default {
  name: 'ToBeResolved',
  components: {
    ApplicationCard
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    selectExam (item) {
      this.$emit('select', item)
    },
    setExam (item) {
      this.$emit('setExam', item)
    }
  }
}
</script>
