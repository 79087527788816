<template>
    <div class="pa-5">
        <MatrixPreview
            v-if="question.wordings.data.type === 'MATRIX'"
            :wordings="[question.wordings]"
            :isResolution=true
            :question="question"
        />
        <OpenEndedPreview
            v-else-if="question.wordings.data.type === 'OPEN_ENDED'"
            :wordings="question.wordings"
        />
        <MultipleChoiceMultipleAnswers
            v-else-if="['MULTIPLE_CHOICE_MULTIPLE_ANSWER', 'MULTIPLE_CHOICE'].includes(question.wordings.data.type)"
            :options="question.wordings.data.options"
            :question="question" />
        <RatingPreview
            v-else-if="question.wordings.data.type === 'RATING'"
            :question="question"
        />
        <SliderPreview
            v-else-if="question.wordings.data.type === 'SLIDER'"
            :params="question.wordings.data.params"
            :value="question.studentAnswer.answers" />
    </div>
</template>

<script>
import MultipleChoiceMultipleAnswers from '../wording-preview/MultipleChoiceMultipleAnswers.vue'
import RatingPreview from '../wording-preview/RatingPreview.vue'
import SliderPreview from '../wording-preview/SliderPreview.vue'
import MatrixPreview from '../wording-preview/MatrixPreview.vue'
import OpenEndedPreview from '../wording-preview/OpenEndedPreview.vue'

export default {
  props: ['question'],
  components: { MatrixPreview, OpenEndedPreview, MultipleChoiceMultipleAnswers, RatingPreview, SliderPreview }
}
</script>
