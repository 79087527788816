<template>
  <v-card-actions>
    <v-spacer />
    <v-btn :loading="sending" large @click="$emit('update')" color="primary">Atualizar</v-btn>
  </v-card-actions>
</template>
<script>
export default {
  props: {
    sending: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
  })
}
</script>
